import React from "react"
import StoryblokService from '../../../utils/storyblok-service'
import Layout from "../../../sb-components/layout"
import Page from '../../../sb-components/page'

class BlogPostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      story: {
        content: this.props.data?.story
          ? JSON.parse(this.props.data.story.content)
          : {}
      }
    };
  }

  async componentDidMount() {
    const story = await this.getInitialStory();
    if (story.content) {
      this.setState({ story })
    }

    setTimeout(() => StoryblokService.initEditor(this), 200)
  };

  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search);

    const { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.location.pathname}`);
    return story
  };

  // getLayoutClasses is needed to set the class that defines the color scheme of the menu.
  getLayoutClass = () => {
    const { story } = this.state;
    return (
      story?.content?.body?.[0].component === 'postItem'
      && story?.content?.body?.[0].isHideHeader)
      ? 'page-blog-hide-header'
      : ''
  };

  // getTemplateClass is needed to set the class that defines the color scheme for the various post templates.
  getTemplateClass = () => {
    const { story } = this.state;

    const template = story?.content?.body?.[0]?.template;
    return template ? `page-blog-${template}` : '';
  };


  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Layout
        className={`page page-blog page-blog-overview ${this.getLayoutClass()} ${this.getTemplateClass()}`}
        full_slug={this.state.story.full_slug}
      >
        <Page blok={{ ...this.state.story.content, title: this.state.story.content || "title" }} />
      </Layout>
    );
  }
}

export default BlogPostPage;
